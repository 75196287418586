import { ID_YANDEX_METRIKA } from 'analytics/const';
import Script from 'next/script';
import type { FC } from 'react';

import { isTestEnvironment } from 'utils/environment';

interface IMetrikaTypes {
  script: JSX.Element;
  noscript: JSX.Element;
}

export const getMetrika = (): IMetrikaTypes => {
  const script = `
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
    ym(${ID_YANDEX_METRIKA},
      "init",
      {
        clickmap:${isTestEnvironment() ? 'false' : 'true'},
        webvisor:${isTestEnvironment() ? 'false' : 'true'},
        trackLinks:true,
        accurateTrackBounce:true,
        ecommerce:"dataLayer"
      }
    );
  `;

  return {
    script: (
      <script
        id="metrikaScript"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __html: script,
        }}
      />
    ),
    noscript: (
      <noscript>
        <div>
          <img
            src={`https://mc.yandex.ru/watch/${ID_YANDEX_METRIKA}`}
            style={{ position: 'absolute', left: '-9999px' }}
            alt=""
          />
        </div>
      </noscript>
    ),
  };
};

export const VarioqubeScript: FC = () => {
  const code = `
    (function(e, x, pe, r, i, me, nt){
    e[i]=e[i]||function(){(e[i].a=e[i].a||[]).push(arguments)},
    me=x.createElement(pe),me.src=r,nt=x.getElementsByTagName(pe)[0],nt.parentNode.insertBefore(me,nt)})
    (window, document, 'script', 'https://abt.s3.yandex.net/expjs/latest/exp.js', 'ymab');
    ymab('metrika.${ID_YANDEX_METRIKA}', 'init', {}, function(answer) {
      const flags = {}
      console.log('AB', answer)
      const flagsFromMetrika = answer.flags;
      if (!!flagsFromMetrika) {
        if (Array.isArray(answer)) {
          answer.flags?.forEach(item => {
            if (item.t === "flag" ) {
              flags[item.n] = item.v
            }
          })
        }
        else if (typeof flagsFromMetrika === "object") {
          try {
            Object.keys(flagsFromMetrika).forEach(flag => {
              flags[flag] = flagsFromMetrika[flag][0];
            })
          } catch (e) {
            console.log('AB flags error', e)
          }
        }
        else {
          console.error('AB flags error')
        }
      }
      console.log('AB flags', flags)
      window._ABFlags_ = flags;
    });
  `;

  return (
    <script
      id="varioqubeScript"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __html: code,
      }}
    />
  );
};

export const MindboxScript: FC = () => {
  const isProd = process.env.NODE_ENV === 'production';

  const code = `
    mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
    mindbox.queue = mindbox.queue || [];
    mindbox('create');
  `;

  return isProd ? (
    <>
      <Script
        id="mindboxInitializationScript"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __html: code,
        }}
      />
      <Script
        id="mindboxTrackerScript"
        type="text/javascript"
        src="https://api.mindbox.ru/scripts/v1/tracker.js"
        async
      />
    </>
  ) : null;
};

export const UxFeedbackScript: FC = () => {
  const code = `
      (function(w, d, u, h, s) {
      w._uxsSettings = {id: 'coq6mzh3p0cas892ohs9c961'};
      h = d.getElementsByTagName('head')[0];
      s = d.createElement('script');
      s.async = 1;
      s.src = u;
      h.appendChild(s);
    })(window, document, 'https://cdn.uxfeedback.ru/widget.js');
  `;
  return (
    <script
      id="uxFeedbackScript"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __html: code,
      }}
    />
  );
};
