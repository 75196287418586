import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { cancelCirculationOrderThunk } from 'sp-redux/thunks/orders/cancelCirculationOrderThunk';
import { deleteCirculationOrderThunk } from 'sp-redux/thunks/orders/deleteCirculationOrderThunk';
import { getCirculationOrderThunk } from 'sp-redux/thunks/orders/getCirculationOrderThunk';
import { getCirculationOrdersThunk } from 'sp-redux/thunks/orders/getCirculationOrdersThunk';
import { getPromotionsOrdersThunk } from 'sp-redux/thunks/orders/getPromotionsOrdersThunk';
import { getServiceInfoByOrderIdThunk } from 'sp-redux/thunks/orders/getServiceInfoByOrderIdThunk';
import { getServicesOrdersThunk } from 'sp-redux/thunks/orders/getServicesOrdersThunk';
import { patchCirculationOrderThunk } from 'sp-redux/thunks/orders/patchCirculationOrderThunk';
import { sendCirculationOrderThunk } from 'sp-redux/thunks/orders/sendCirculationOrderThunk';
import { getOrderInfoByOrderId } from 'sp-redux/thunks/services/getOrderInfoByOrderId';

import type { IOrdersState } from './types';

const initialState: IOrdersState = {
  isFetched: false,
  isFetching: true,
  orders: {
    promotion: {},
    serviceOrder: {},
    promotions: [],
    circulation: [],
    services: [],
    errors: [],
  },
  orderCirculation: null,
  pagination: { circulation: { count: 0, next_page: '', previous_page: '' } },
  circulationOrderErrors: [],
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPromotionsOrdersThunk.pending, state => {
      state.isFetching = true;
      state.orders.promotions = [];
    });

    builder.addCase(getPromotionsOrdersThunk.fulfilled, (state, action) => {
      state.isFetching = false;
      state.orders.errors = action.payload.errors;
      state.orders.promotions = action.payload.result;
    });

    builder.addCase(getCirculationOrdersThunk.pending, state => {
      state.orders.circulation = [];
      state.isFetching = true;
    });

    builder.addCase(getServicesOrdersThunk.pending, state => {
      state.orders.services = [];
      state.isFetching = true;
    });

    builder.addCase(getServicesOrdersThunk.fulfilled, (state, action) => {
      state.isFetching = false;
      state.orders.errors = action.payload.errors;
      state.orders.services = action.payload.result;
    });

    builder.addCase(getCirculationOrdersThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      state.orders.errors = action.payload.errors;
      state.orders.circulation = action.payload.result;

      if (action.payload.pagination) {
        state.pagination.circulation = action.payload.pagination;
      }
    });

    builder.addCase(getCirculationOrderThunk.fulfilled, (state, action) => {
      state.orders.errors = action.payload.errors;
      state.orderCirculation = action.payload.result;
    });

    builder.addCase(cancelCirculationOrderThunk.fulfilled, (state, action) => {
      state.circulationOrderErrors = action.payload.errors;

      if (state.circulationOrderErrors.length === 0) {
        state.orderCirculation = action.payload.result;
      }
    });

    builder.addCase(sendCirculationOrderThunk.fulfilled, (state, action) => {
      state.circulationOrderErrors = action.payload.errors;

      if (state.circulationOrderErrors.length === 0) {
        state.orderCirculation = action.payload.result;
      }
    });

    builder.addCase(patchCirculationOrderThunk.fulfilled, (state, action) => {
      state.circulationOrderErrors = action.payload.errors;

      if (state.circulationOrderErrors.length === 0) {
        state.orderCirculation = action.payload.result;
      }
    });

    builder.addCase(deleteCirculationOrderThunk.fulfilled, (state, action) => {
      state.circulationOrderErrors = action.payload.errors;
    });

    builder.addCase(getOrderInfoByOrderId.fulfilled, (state, action) => {
      state.isFetched = true;
      state.orders.errors = action.payload.errors;
      state.orders.promotion = action.payload.result;
    });

    builder.addCase(getServiceInfoByOrderIdThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.orders.errors = action.payload.errors;
      state.orders.serviceOrder = action.payload.result;
    });

    builder.addCase(HYDRATE, (state, action: IHydrateAction<IOrdersState>) => {
      return action.payload.orders;
    });
  },
});

export const ordersReducer = ordersSlice.reducer;
