import * as Sentry from '@sentry/browser';
import { YANDEX_METRIKA_AB_COOKIE_NAME } from 'analytics/const';
import { logFirebaseEvent } from 'analytics/firebase';
import { getAbData } from 'analytics/getABData';
import {
  MindboxScript,
  UxFeedbackScript,
  VarioqubeScript,
} from 'analytics/headScriptAnalytics';
import type { IPublicRuntimeConfig } from 'app.types';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { parse, stringify } from 'query-string';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';

import { UTM_PARAMS } from 'constants/utm';

import { Toaster } from 'components/UIKit/Toaster';
import { YandexMetrikaScripts } from 'components/YandexMetrikaScripts';

import type { TAppState } from 'sp-redux';
import { wrapper } from 'sp-redux';
import { getAllNotificationsThunk } from 'sp-redux/thunks/notifications/getAllNotificationsThunk';
import { getNotificationsCountThunk } from 'sp-redux/thunks/notifications/getNotificationsCountThunk';
import { getProfileThunk } from 'sp-redux/thunks/profile/getProfile';
import { getUserInfoThunk } from 'sp-redux/thunks/userInfo';

import { isBrowser } from 'utils/environment';
import { noop } from 'utils/noop';

import 'styles/globals.scss';

function MyCustomApp({
  Component,
  pageProps,
  err,
}: AppProps & { err: Record<string, string> }): JSX.Element {
  const { publicRuntimeConfig } = getConfig();
  const { analytics }: IPublicRuntimeConfig = publicRuntimeConfig;

  const store = useSelector((state: TAppState) => state);
  const { isLogged } = store.userInfo;

  if (!isLogged && isBrowser) {
    const queryParams = parse(location.search);

    const utmParams: Record<typeof UTM_PARAMS[number], string> = {};

    UTM_PARAMS.forEach((name: string) => {
      const value = queryParams[name];
      if (typeof value === 'string') {
        utmParams[name] = value;
      }
    });

    if (!sessionStorage.getItem('utmParams')) {
      sessionStorage.setItem('utmParams', stringify(utmParams));
    }
  }

  useEffect(() => {
    logFirebaseEvent('init firebase');

    if (analytics.enable) {
      TagManager.initialize({
        gtmId: 'GTM-WK9QJSB',
      });
    }

    if (isBrowser && typeof window.ga !== 'function') {
      window.ga = noop;
    }
  }, [analytics.enable]);

  useEffect(() => {
    window.addEventListener('storage', event => {
      if (
        event.key === 'oauthLitres' &&
        event.newValue === 'successOauthLitres'
      ) {
        localStorage.setItem('oauthSuccess', 'success');
        localStorage.removeItem('oauthLitres');
        window.location.reload();
      }
    });
  }, []);

  // Костыль для того чтобы не терять сообщение об ошибке https://github.com/vercel/next.js/issues/8592
  return (
    <>
      <YandexMetrikaScripts />
      <MindboxScript />
      <Head>
        <VarioqubeScript />
        <UxFeedbackScript />
      </Head>
      <Component {...pageProps} err={err} />
      <Toaster />
    </>
  );
}

// TODO: Добавил getInitialProps так как на страницах без SSR нет доступа к
// runtimeConfig и мы не имеем доступа к переменным окружения из файла - next.config,
// следовательно запросы падают, так как в хуке doFetch нам нужен доступ к runtimeConfig
// https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration

MyCustomApp.getInitialProps = wrapper.getInitialAppProps(
  store =>
    async ({ ctx }: AppContext): Promise<AppInitialProps> => {
      const cookie = ctx.req?.headers.cookie;
      const sessionId = cookie?.match(/sessionid/gi);
      if (sessionId) {
        await store.dispatch(getUserInfoThunk(ctx));
        const isLogged = store.getState().userInfo.isLogged;
        if (isLogged) {
          await store.dispatch(getNotificationsCountThunk(ctx));
          await store.dispatch(getProfileThunk(ctx));
          await store.dispatch(
            getAllNotificationsThunk({
              context: ctx,
            }),
          );
        }
      }
      let cookiesToSet = [''];

      try {
        let url = undefined;
        if (ctx.req?.headers.host && ctx.req.url) {
          url = `https://${ctx.req.headers.host}${ctx.req.url}`;
        }
        const ymabParam = cookie
          ?.split(';')
          .find(item => item.includes(YANDEX_METRIKA_AB_COOKIE_NAME))
          ?.trim()
          .split('=')[1];
        const cookieHeadersArray = await getAbData(url, ymabParam);

        // Фильтруем только те куки, которые еще ни разу не были выставлены
        // Это нужно, чтобы можно было зафорситься в АБ
        cookiesToSet = cookieHeadersArray.filter(item => {
          return !cookie?.includes(item.split('=')[0]);
        });

        ctx.res?.setHeader('set-cookie', [...cookiesToSet]);
      } catch (e) {
        Sentry.captureException('Failed to fetch AB data', {
          extra: { error: e },
        });
      } finally {
        return {
          pageProps: {},
        };
      }
    },
);

export default wrapper.withRedux(MyCustomApp);
